<template>
  <div>
    <PageTitlebar title="Reporting" :title-icon="getAssetIcon('reporting')">
      <template v-slot:sub-title>
        Access your data and monitor performance.
      </template>

      <template v-slot:actions>
        <DateRangeSelector
          :initial-start-date="startDate"
          :initial-end-date="endDate"
          @update="
            dates => {
              updateDateRange(dates);
              fetchReport();
            }
          "
        />
      </template>
    </PageTitlebar>

    <DataTable
      :table-key="tableKey"
      row-key="id"
      title=""
      :columns="columns"
      :rows="rows"
      :totals-row="totalsRow"
      :loading="loading"
      :pagination="pagination"
      :rows-per-page-options="[25, 50]"
      :pivot-table="true"
      :downloadable="false"
      :refreshable="true"
      :customizable="true"
      :filterable="true"
      show-side-panel
      :server-side="true"
      :searchable="false"
      :disable-state="false"
      :dimension-filter-options-by-column="dimensionFilterOptionsByColumn"
      :time-last-updated="reportingTimeLastUpdated"
      @update-rows="val => handleUpdateRowsEvent(val)"
    />
  </div>
</template>

<script>
import PageTitlebar from "@/components/UI/PageTitlebar";
import DateRangeSelector from "@/components/UI/DateRangeSelector";
import DataTableMixin from "@/mixins/DataTableMixin";
import DataTable from "@/components/UI/DataTable";
import axios from "axios";

export default {
  name: "ReportingOverview",
  mixins: [DataTableMixin],
  components: {
    DataTable,
    PageTitlebar,
    DateRangeSelector
  },
  data() {
    return {
      tableKey: "advertiser_reporting",
      loading: true,
      refreshInterval: null,
      startDate: this.$route.query.start_date
        ? this.$route.query.start_date
        : this.$dayjs()
            .tz()
            .format("YYYY-MM-DD"),
      endDate: this.$route.query.end_date
        ? this.$route.query.end_date
        : this.$dayjs()
            .tz()
            .format("YYYY-MM-DD"),
      datePreset: this.$route.query.date_preset
        ? this.$route.query.date_preset
        : "",
      pagination: {
        sortBy: "spend",
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 1000
      },
      rows: [],
      columns: [
        {
          name: "date",
          label: "Date",
          field: "date",
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions.split(",").includes("date")
          )
        },
        {
          name: "hour",
          label: "Hour of Day",
          field: "hour",
          format: val => (!val ? "-" : val + ":00"),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions.split(",").includes("hour")
          )
        },
        {
          name: "advertiser_account",
          label: "Account",
          field: "advertiser_account_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "advertiser_account_name",
          type: "dimension",
          visible: !!(
            !this.$route.query.initial_dimensions ||
            (this.$route.query.initial_dimensions &&
              this.$route.query.initial_dimensions
                .split(",")
                .includes("advertiser_account"))
          )
        },
        /*{
          name: "product_type",
          label: "Product Type",
          field: "product_type",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(this.$route.query.initial_dimensions && this.$route.query.initial_dimensions.split(",").includes("product_type"))
        },*/
        {
          name: this.$store.state.productTypeConfigs.type,
          label: this.$store.state.productTypeConfigs.typeFormatted,
          field: this.$store.state.productTypeConfigs.identifierField,
          format: (val, row) =>
            !val
              ? "-"
              : row[this.$store.state.productTypeConfigs.nameField] +
                "<p class='text-subtle'>" +
                val +
                "</p>",
          sortable: true,
          sortField: "vehicle_vin",
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions.split(",").includes("vehicle")
          )
        },
        {
          name: "device",
          label: "Device",
          field: "device",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions.split(",").includes("device")
          )
        },
        {
          name: "device_type",
          label: "Device Type",
          field: "device_type",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions
              .split(",")
              .includes("device_type")
          )
        },
        {
          name: "device_manufacturer",
          label: "Device Manufacturer",
          field: "device_manufacturer",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions
              .split(",")
              .includes("device_manufacturer")
          )
        },
        {
          name: "device_model",
          label: "Device Model",
          field: "device_model",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions
              .split(",")
              .includes("device_model")
          )
        },
        {
          name: "operating_system",
          label: "Operating System",
          field: "operating_system",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions
              .split(",")
              .includes("operating_system")
          )
        },
        {
          name: "browser",
          label: "Browser",
          field: "browser",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions.split(",").includes("browser")
          )
        },
        {
          name: "browser_type",
          label: "Browser Type",
          field: "browser_type",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: !!(
            this.$route.query.initial_dimensions &&
            this.$route.query.initial_dimensions
              .split(",")
              .includes("browser_type")
          )
        },
        {
          name: "spend",
          label: "Spend",
          field: "spend",
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "impressions",
          label: "Impressions",
          field: "impressions",
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "visitors",
          label: "Reach",
          field: "visitors",
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "clicks",
          label: "Clicks",
          field: "clicks",
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "cpm",
          label: "CPM",
          field: "cpm",
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "ctr",
          label: "CTR",
          field: "ctr",
          sortable: true,
          type: "metric",
          metricType: "number"
        },
        {
          name: "cpc",
          label: "CPC",
          field: "cpc",
          sortable: true,
          type: "metric",
          metricType: "number"
        }
      ],
      totalsRow: {},
      dimensionFilterOptionsByColumn: {},
      reportingTimeLastUpdated: ""
    };
  },
  computed: {},
  beforeMount() {
    if (this.datePreset.length > 0) {
      let dateRange = this.getDatePresetRange(this.datePreset);
      this.startDate = dateRange.from;
      this.endDate = dateRange.to;
    }

    let urlParamDataTableState = this.loadDataTableStateFromURLParam(
      this.tableKey,
      this.columns
    );

    this.columns = urlParamDataTableState.columns;

    if (Object.keys(urlParamDataTableState.pagination).length > 0) {
      this.pagination = urlParamDataTableState.pagination;
    }
  },
  mounted() {
    this.removeUrlParamsFromWindow(["initial_dimensions"]);
    this.fetchReport();
  },
  beforeRouteLeave() {
    clearInterval(this.refreshInterval);
    this.$store.commit("CLEAR_PENDING_REQUESTS", {
      signalIdentifier: "reportingOverview"
    });
  },
  methods: {
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.addUrlParamsToWindow([
          { name: "start_date", value: this.startDate },
          { name: "end_date", value: this.endDate }
        ]);
      }
    },
    fetchReport(dataTableState = null) {
      this.loading = true;

      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(
        () => this.fetchReport(dataTableState),
        300000
      );

      // If we dont have a dataTableState (passed in events emitted by the datatable), then fallback to default initial request.
      if (!dataTableState) {
        dataTableState = {
          columns: this.columns,
          pagination: this.pagination,
          searchFilter: ""
        };
      }

      // Remap our sort field (ex, brand vs brand_name)
      let sortField = dataTableState.pagination.sortBy;
      let sortFieldColumn = dataTableState.columns.filter(
        col => col.name === sortField
      );
      if (
        sortFieldColumn.length > 0 &&
        sortFieldColumn[0].sortField &&
        sortFieldColumn[0].sortField.length > 0
      ) {
        sortField = sortFieldColumn[0].sortField;
      }

      // Configure sorting array. (additional default sorting for all other dimensions, in order to maintain uniqueness in ordering across pages)
      let sortingArray = [
        {
          field: sortField,
          direction: dataTableState.pagination.descending ? "desc" : "asc"
        }
      ];
      dataTableState.columns
        .filter(
          fc => fc.visible && fc.type === "dimension" && fc.field !== sortField
        )
        .forEach(column => {
          sortingArray.push({
            field: column.sortField ? column.sortField : column.name,
            direction: "asc"
          });
        });

      // Fetch Report
      this.$store
        .dispatch("advertiserReporting/POST_REQUEST", {
          signalIdentifier: "reportingOverview",
          params: {
            report_payload: {
              start_date: this.startDate,
              end_date: this.endDate,
              options: {
                pagination: {
                  per_page: dataTableState.pagination.rowsPerPage,
                  page: dataTableState.pagination.page
                },
                sorting: sortingArray,
                include_results_name_fields: true,
                include_results_id_fields: true,
                dimension_key_indexed_results: true,
                include_time_last_updated: true,
                format_results: true,
                enable_drilldown_mode: true,
                include_totals: true
              },
              dimensions: dataTableState.columns
                .filter(c => c.type === "dimension" && c.visible)
                .reduce(
                  (dimensions, dimensionColumn) =>
                    Object.assign(dimensions, {
                      [dimensionColumn.name]:
                        dimensionColumn.filterValues &&
                        dimensionColumn.filterValues.in.length > 0
                          ? {
                              filter: { in: dimensionColumn.filterValues.in }
                            }
                          : []
                    }),
                  {}
                ),
              metrics: dataTableState.columns
                .filter(c => c.type === "metric" && c.visible)
                .reduce(
                  (metrics, metricColumn) =>
                    Object.assign(metrics, {
                      [metricColumn.name]:
                        metricColumn.filterValues &&
                        metricColumn.activeFilterCount > 0
                          ? {
                              filter: {
                                ...(metricColumn.filterValues.gt.length > 0 && {
                                  gt: metricColumn.filterValues.gt
                                }),
                                ...(metricColumn.filterValues.lt.length > 0 && {
                                  lt: metricColumn.filterValues.lt
                                })
                              }
                            }
                          : []
                    }),
                  {}
                )
            }
          }
        })
        .then(report => {
          let reportMetadata = "metadata" in report ? report.metadata : {};

          this.rows = "rows" in report ? Object.values(report.rows) : [];

          let dimensionFacets =
            "dimension_facets" in reportMetadata
              ? reportMetadata.dimension_facets
              : {};
          if (dimensionFacets && dimensionFacets.hour) {
            dimensionFacets.hour = dimensionFacets.hour.map(hourFacet => {
              hourFacet.label = hourFacet.label + ":00";
              return hourFacet;
            });
          }
          this.dimensionFilterOptionsByColumn = dimensionFacets;

          this.pagination.rowsNumber =
            "total_rows" in reportMetadata
              ? parseInt(reportMetadata.total_rows)
              : 0;

          this.totalsRow =
            "totals" in reportMetadata ? reportMetadata.totals : {};

          this.reportingTimeLastUpdated =
            "time_last_updated" in reportMetadata
              ? reportMetadata.time_last_updated
              : "";

          this.loading = false;
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          this.loading = false;
        });
    },
    handleUpdateRowsEvent(columns) {
      this.fetchReport(columns);
    }
  }
};
</script>

<style scoped lang="scss"></style>
